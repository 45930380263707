<template>
	<div>
		<div v-if="!addOrEditReady">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else>
			<form @submit.prevent="checkForm">
                <div v-if="saved" class="col-12">
                    <div class="form-group text-right">
                        <button class="btn btn-primary rounded-pill" @click='callPrintContracts'>
                            <font-awesome-icon v-if="spinning_print" :icon="['fas', 'spinner']" pulse /> 
                            <font-awesome-icon v-else :icon="['far', 'file-pdf']" class="ml-1" /> 
                            {{$t("global.imprimer")}}
                        </button>
                    </div>
                </div>

				<div class="row">
					<template v-if="Object.keys(form.tiers).length > 0">
						<div class="col-12">
							<Etalon 
								ref="Etalon"
								:stallion_id="contract.contract_config.horse.horse_id"
								v-model="stallion"
								:contractconfig_id.sync="contractconfig_id"
								:season.sync="season"
								:disabled="isDisabled"
							>
							</Etalon>

							<div class="row">
								<div class="col-9">
									<div class="form-group">
										<label for="season">{{ $t('monte.jument') }}</label>
										<e-select
											v-model="form.jument"
											track-by="horse_id"
											label="horse_nom"
											:placeholder="labelTitleJument"
											:selectedLabel="selectedLabelJument"
											:options="juments"
											:allow-empty="false"
											:show-labels="false"
											:loading="loadingJument"
											:disabled="isDisabled"
											:sortable="false"
											:class="{ 'is-invalid': errors && errors.indexOf('jument') > -1 }"
										></e-select>
									</div>
								</div>
								<button class="col-3 mt-4 mb-2 btn btn-primary" @click="add_mare" >
									{{ $t('monte.add_jument') }}  
									<font-awesome-icon :icon="['fal', 'plus']" /> 
								</button>
							</div>

							<Modele 
								ref="Modele"
                                v-show="false"
								:disabled="isDisabled"
								v-model="modele"
								:contractconfig_id="contractconfig_id"
                                auto-select-default-ca
							></Modele>

							<TypeMonte 
								ref="TypeMonte"
								:contractconfig_id="contractconfig_id"
								:avenant_typemonte="avenant.avenant_typemonte"
								v-model="type_monte"
								:disabled="isDisabled"
							></TypeMonte>

							<div class="form-group" v-if="show_paillettes">
								<label for="nombre_paillette" class="col-form-label">{{ $t("monte.nombre_paillette") }} *</label>
								<input type="text" id="nombre_paillette" class="form-control" v-model="nombre_paillette" required  autocomplete="chrome-off" disabled="true">
							</div>

                            <div class="row">
                                <ChangementCMEP
                                    class="col"
                                    ref="changementCMEP"
                                    :contract_id="contract_id"
                                    :avenant.sync="avenant"
                                    :tiers_tab.sync="cmep_tiers"
                                    :cmep.sync="form.cmep"
                                    :disabled="isDisabled || disabledCmep"
                                    :required="false"
                                    :type_monte="type_monte_code"
                                >
                                </ChangementCMEP>

                                <b-button class="col-auto mt-4 mb-2" @click="ask_manual_cmep = !ask_manual_cmep" >
                                    {{ $t('monte.add_lieu') }}  
                                    <font-awesome-icon :icon="['fal', 'plus']" /> 
                                </b-button>
                            </div>
                            
                            <b-input 
                                class="mb-2" 
                                v-if="ask_manual_cmep" 
                                v-model="form.contract_note"
                                placeholder="Entrez une adresse de monte"
                            />

							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<label for="commentaire">{{ $t('gynecologie.ajouter_commentaire') }}</label>
										<textarea id="commentaire" rows="5" class="form-control" v-model="commentaire"></textarea>
									</div>
								</div>
							</div>

                            <div v-if="type_monte && type_monte.articles && type_monte.articles.length > 0" class="form-group mt-2">
                                <div class="row col">
                                    <label class="col-6" for="cond_name">{{ $t('monte.conditions') }}</label>
                                    <label class="col-6" for="cond_value">{{ $t('monte.montant_ttc') }}</label>
                                </div>

                                <div class="row col" v-for="(article, idx) in type_monte.articles" :key="'article_'+idx">
                                    <b-input class="col-6" :value="article.articles_label" readonly />
                                    <b-input class="col-6" :value="priceFormat(article.articles_ttc)" readonly />
                                </div>
                            </div>
						</div>

                        <AckTerms 
                            :acknowledged.sync="acknowledged"
                            :read-only="saved || isDisabled"
                        />

						<div class="col-12" v-if="form_message !== ''">
							<ErrorAlert :messageI18n="form_message" />
						</div>

						<div class="col-12">
							<div class="form-group text-center">
                                <button v-if="!saved" class="btn btn-primary rounded-pill" @click='saveInfo' :disabled="!acknowledged">
                                    <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                                    <font-awesome-icon v-else :icon="['fal', 'save']" class="ml-1" />
                                     {{$t("global.valider")}}
                                </button>
                                <button v-else class="btn btn-primary rounded-pill" @click='redirectPostSave'>
                                    {{$t("global.suivant")}}
                                </button>
							</div>
						</div>
					</template>
				</div>
			</form>

		<b-modal ref="modal" hide-footer>
            <template v-slot:modal-title>
                {{ $t("action.online_paiement") }}
            </template>

            <!-- <div v-if="!modal_loaded">
                <LoadingSpinner class="col-12" />
            </div> -->

			<PaymentForm
				ref="PaymentForm"
				:invoicedetails_ids="invoicedetails_ids"
				:details="details"
			/>
        </b-modal>


		</div>
	</div>
</template>

<script type="text/javascript">
    import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import ContractMixin from "@/mixins/Contract.js"
	import ModelMixin from "@/mixins/Model.js"
	import TiersMixin from "@/mixins/Tiers.js"
	import HorseMixin from "@/mixins/Horse.js"
    import Marketplace from '@/mixins/Marketplace.js'
    import Invoice from '@/mixins/Invoice.js'

	import ShutterContract from "@/mixins/shutters-manager/Contract.js"
	import Shutter from '@/mixins/Shutter.js'
	import _sortBy from 'lodash/sortBy'

	export default {
		name: "ContractForm",
		props: {
			contract_id: {
				type: Number,
				default: () => ( 0 )
			},
			from: {
				type: String,
				default: () => ( "" )
			},
			stallion_id: {
				type: Number,
				default: () => ( 0 )
			},
            mare_id: {
				type: Number,
				default: () => ( 0 )
			}
		},
		mixins: [Navigation, ContractMixin, ModelMixin, TiersMixin, Shutter, ShutterContract, HorseMixin, Marketplace, Invoice],
		data () {
			return {
				form_message: "",
				addOrEditReady: false,
				isDisabled: true,
				disabledCmep: false,
				loadingTiers: false,
				loadingStallion: false,
				loadingModele: false,
				loadingTypeMonte: false,
				require_infos: true,
				loadingJument: false,
				conditions_spe: false,
				table_busy: false,
				contractconfig_id: 0,
				processing: false,
				commentaire: '',
				errors: [],
				conditions: [],
				juments: [],
				articles: [],
				tiers: [],
				formattedAccount: [],
				cmep_tiers: [],
				entities: [],
				contract_config: {}, //Contract config selectionné en fonction de l'étalon et de la saison
				contract: {}, //Contract
				avenant:  {}, //Avenant utilisé

				form: {
					tiers:  {},
					season:  {},
					stallion:  {},
					modele:  null,
					type_monte:  null,
					jument: null,
					cmep: null,
					courtier: null,
					avenant_comment: '',
					avenant_justification: '',
					avenant_transfert: 0,
					contract_note: '',
				},
				labelTitleTiers: this.getTrad("monte.rechercher_tiers"),
				selectedLabelTiers: this.getTrad("global.selected_label"),
				labelTitleJument: this.getTrad("monte.rechercher_jument"),
				selectedLabelJument: this.getTrad("global.selected_label"),
				labelTitleCmep: this.getTrad("monte.rechercher_cmep"),
				selectedLabelCmep: this.getTrad("global.selected_label"),
				labelTitleCourtier: this.getTrad("monte.rechercher_courtier"),
				selectedLabelCourtier: this.getTrad("global.selected_label"),
				extern_slot_columns: [
				    'avenantarticles_ht',
				    'tiers.tiers_rs',
				    'author.tiers_rs',
				    'avenantarticles_invoiceable_date',
				],

				/* NEW */
				season: {},
				stallion: {},
				modele: {},
				type_monte: {},
				avenant_article: {},
				show_bons: false,

				spinning_print: false,
				spinning_send: false,
				note: null,
				comment: null,
				justification: null,
				isAvenantCancel: false,
				season_mare: null,
				season_id: null,
                saved: false,
                has_invoice: false,
                ask_manual_cmep: false,
                acknowledged: false,
				invoice_mode: "after",


				modal_loaded: false,
				items_to_pay: null,
                url_boutique: null,
                marketplace: 'mangopay',
				accounts: [],
                dates: {},
                go_atos: false,
                go_paybox: false,
                atos_infos: {},
                paybox_infos: {},
                invoicedetails_ids: [],
                details: [],
                compte: null,
				current_date: new Date(),
				total_balance: 0,
				type_monte_code: '',
				nombre_paillette: 0
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async initContract() {
				this.contract = await this.getContract(this.contract_id)

				if(!this.contract.contract_config) {
					this.contract.contract_config = {horse: {}}
				}

				this.contractconfig_id = this.contract.contract_config.contractconfig_id
				this.avenant = this.contract.last_avenant

				this.contractInvoiceMode()
                    .then(res => {
						this.invoice_mode = res.contract_invoice_mode
					})
			},
			async initTiers() {
				this.loadingTiers = true
				this.cmep_tiers = await this.loadCmepTiersWithCountry()
				this.loadingTiers = false

				let preselection_tiers = []
                
				if(this.contract.tiers != undefined) {
					this.form.tiers = this.contract.tiers
				}
				else {
					if(this.avenant.avenant_horse != null) {
						//Allez chercher dans tiers_horse le 1er que je trouve
						preselection_tiers = await this.loadHorseTiers(this.avenant.avenant_horse)

						if(preselection_tiers.length > 0) {
							this.form.tiers = preselection_tiers[0]
						}
					}
				}

				//Je vais restreindre mes tiers si je viens de la fiche jument
				if(this.from == "mareList") {
					this.juments = this.juments.filter(el => el.horse_id == this.avenant.avenant_horse)
				}
			},
			async initJuments() {
				this.loadingJument = true
				let juments = await this.loadJuments()

				let contract_sire = this.getConfig('contract_sire_obligatory') || false
				if(contract_sire) {
					juments = juments.filter(jument => jument.horse_sire !== null && jument.horse_sire !== '')
				}

				this.juments = _sortBy(juments, ['horse_nom'])

                if(this.mare_id !== 0) {
                    this.form.jument = this.juments.find(j => j.horse_id == this.mare_id)
                }

				this.loadingJument = false
			},
			async initForm() {
				// Si l'avenant est encore en brouillon
				if(this.avenant.avenant_type == 1) {
					this.isDisabled = false
				}

				if(this.avenant.avenant_status == 3) {
					this.isDisabled = true
				}

				if(this.contract.courtier != undefined) {
					this.form.courtier = this.contract.courtier
				}

				this.form.contract_note = this.contract.contract_note
				this.form.avenant_justification = this.avenant.avenant_justification
				this.form.avenant_comment = this.avenant.avenant_comment
				this.form.avenant_transfert = this.avenant.avenant_transfert

				this.note = this.contract.contract_note
				this.comment = this.avenant.avenant_comment
				this.justification = this.avenant.avenant_justification

				if(this.avenant.horse != undefined) {
					this.form.jument = this.avenant.horse
				}

				if(this.avenant.cmep != undefined) {
					this.form.cmep = this.avenant.cmep
				}

				if(!this.contract_id && this.stallion_id) {
					this.contract = {config: { contractconfig_horse: this.stallion_id }}
				}
				else if(this.contract_id && this.stallion_id) {
					this.contract.contract_config = { horse: {horse_id: this.stallion_id }}
				}
			},
			async init_component() {
				this.addOrEditReady = false

				await this.initContract()

				let promises = []

				promises.push(this.initTiers())

				promises.push(this.initJuments())

				promises.push(this.initForm())

				await Promise.all(promises)

				const seasons = await this.loadSeasons()
				const date = new Date()
				this.season = seasons.find(season => Date.parseTz(season.season_start) <= Date.parseTz(date) && Date.parseTz(season.season_end) >= Date.parseTz(date))
				this.season_id = this.season.season_id

				this.addOrEditReady = true
			},
			async saveInfo() {
				if(this.processing == false) {
					this.processing = true

					this.errors = []
					this.form_message = ''

					if(this.contractconfig_id == 0) {
						this.errors.push("season")
						this.errors.push("stallion")
						this.form_message = "monte.contract_config_not_found"
					}
					else if (!this.type_monte) {
						this.errors.push('type_monte')
						this.form_message = 'monte.type_monte_not_set'
					}

					if(!this.form.cmep) {
						this.errors.push('cmep')
						this.form_message = 'monte.type_cmep_not_set'
					}

					if(!this.form.jument) {
						this.errors.push('jument')
						this.form_message = 'monte.type_jument_not_set'
					}

					if (this.form_message) {
						this.processing = false
						return
					}
					

					let note = this.form.contract_note
					if(note == '' || note == null) {
						note = this.commentaire
					}
					else {
						note += '<hr>' + this.commentaire
					}

					let infos = {
						cmep: this.form.cmep ? this.form.cmep.tiers_id : null,
						type_monte: this.type_monte.id,
						modele: this.modele.model_id,
						jument: this.form.jument.horse_id,
						contractconfig_id: this.contractconfig_id,
						avenant: this.avenant.avenant_id,
						change_avenant: !this.isDisabled,
                        season: this.season.season_id,
                        paillettes: this.nombre_paillette,
                        note: note
					}

					await this.saveContract(this.contract.contract_id, infos)
                    const invoice = await this.contractAutoInvoice(this.contract.contract_id, this.invoice_mode)

					if(invoice.invoicedetails_id != undefined) {
						//Je vais sur le formulaire de paiement
						this.$refs.modal.show()
						this.invoicedetails_ids = invoice.invoicedetails_id
						this.details = invoice.tab_invoice
						
						this.processing = false
					}
					else{
						this.has_invoice = Boolean(invoice)

						if(this.has_invoice) {
							this.redirectPostSave()
						}

						this.successToast("toast.info_modif_succes")
						await this.init_component()

						this.acknowledged = true
						this.isDisabled = true
						this.processing = false
					}
					this.saved = true
				}
			},
					

			addAvenant() {
				this.$refs.modalAddAvenant.openModal()
			},
			signature() {
				this.$refs.modalContractSignature.openModal()
			},
			etatFacturation(){
				this.show_bons = !this.show_bons
			},
			async callPrintContracts() {
				this.spinning_print = true
				const result = await this.printContract(parseInt(this.contract.contract_id))
                
				if(result == null) {
					this.failureToast('monte.print_error')					
				}
                this.spinning_print = false
			},
			async callSendContracts(contract_id){
				this.spinning_send = true
				let result = await this.sendMailContracts([contract_id])

				if(result != null) {
					this.successToast('monte.send_success')
				}
				else {
					this.failureToast('monte.send_error')
				}
				this.spinning_send = false
			},
			async callCancelContracts(contract_id){
				this.isAvenantCancel = true
				await this.addAvenant()
			},
			updateEditorNote(val) {
				this.form.contract_note = val.val
			},
			updateEditorComment(val){
				this.form.avenant_comment = val.val
			},
			updateEditorJustification(val){
				this.form.avenant_justification = val.val
			},
            redirectPostSave() {
                if(this.has_invoice) {
                    this.$router.push({ name: 'addressedInvoices' })
                }
                else {
                    this.shutterPanel().close('contract-form')
                    EventBus.$emit('ContractList::backToList')
                    this.init_component()
                }
            },
			add_mare() {
				this.$router.push({ name: 'horseAjout', params: { from: 'contractAjout', contract_id: this.contract_id }})
			}
		},
		computed: {
			show_paillettes() {
				return ['iac', 'iarp'].includes(this.type_monte_code)
			}
		},
		watch: {
			async type_monte(val) {
				if(val && val.type_monte) {
					this.type_monte_code = val.type_monte
					this.nombre_paillette = val.paillettes
				}
				if(val && val.type_monte !== 'iac' && val.type_monte !== 'iart' && val.type_monte !== 'iarp') {
					let default_cmep = await this.getContractConfigDefaultCmep(val.contractconfig)
					if(default_cmep && default_cmep.tiers_id) {
						this.form.cmep = default_cmep//this.cmep_tiers.find(t => t.tiers_id == default_cmep.tiers_id)
						this.disabledCmep = true
					}
				}
				else {
					this.disabledCmep = false
				}
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
			ChangementCMEP : () => import('@/components/Contract/AvenantType/ChangementCMEP'),
			Saison : () => import('@/components/Contract/Type/Saison'),
			Etalon : () => import('@/components/Contract/Type/Etalon'),
			Modele : () => import('@/components/Contract/Type/Modele'),
			TypeMonte : () => import('@/components/Contract/Type/TypeMonte'),
            AckTerms : () => import('@/components/Contract/PrivacyPolicyAck'),
            RequiredInfosUser : () => import('GroomyRoot/components/Marketplace/RequiredInfosUser'),
            PaymentForm : () => import('@/components/Payment/Form'),
		}
	}
</script>
